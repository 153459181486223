export default [{
  id: 120000173622389,
  vendor_code: 1239993242,
  barcode: 1233494304,
  image: require('@/assets/images/product/sample.png'),
  name: 'Бордовые женские брюки',
  brand: 'Massimo Dutti',
  category: 'Толстовка',
  status: 'ok'
}, {
  id: 12000017303493049,
  vendor_code: 93490349,
  barcode: 123349444949,
  image: require('@/assets/images/product/sample.png'),
  name: 'Бордовые женские брюки',
  brand: 'Massimo Dutti',
  category: 'Толстовка',
  status: 'trash'
}]
