<template>
  <div class="product-import-upload">
    <p class="product-import-upload__text">
      Здесь вы можете посмотреть статус заданий на выгрузку и удалить товары из задания на выгрузку.
    </p>

    <header class="product-import-upload__header">
      <dashboard
        type="import"
        :list="{
          id: '00123',
          title: 'Aли (Акция 2.0)',
          source: 'Список 1.XSLX',
          loaded: 920,
          amount: 1764,
          date: '21.03.2021',
          status: 'working',
          progress: 56
        }"
      />
    </header>

    <panel class="product-import-upload__content">
      <heading
        level="2"
      >
        Загрузка
      </heading>

      <div class="product-import-upload__heading">
        <div class="product-import-upload__errors" />

        <div class="product-import-upload__button">
          <btn
            block
            bordered
          >
            Удалить
          </btn>
        </div>
      </div>

      <grid
        class="product-import-upload__grid"
        auto
        selectable
        :columns="columns"
        :cells="cells"
      >
        <template #id="{ rowIndex }">
          <div class="product-import-upload__number">
            <span>
              {{ rowIndex + 1 }}
            </span>
          </div>
        </template>

        <template #image="{ image }">
          <img
            :src="image"
            class="product-import-upload__image"
          >
        </template>

        <template #status="{ row }">
          <ul class="product-import-upload__actions">
            <li class="product-import-upload__action">
              <button
                v-if="row.status === 'remove'"
                type="button"
                class="product-import-upload__remove"
                @click="$emit('remove', row)"
              >
                <svg-icon
                  name="trash"
                  class="product-import-upload__remove-icon"
                />
              </button>

              <svg-icon
                :name="row.status"
                class="product-import-upload__icon"
              />
            </li>
          </ul>
        </template>
      </grid>

      <pagination
        :data="pagination"
        :current-page="currentPage"
        @change="page => currentPage = page"
      />
    </panel>
  </div>
</template>

<script>
import Dashboard from '@/components/Product/Dashboard/Index.vue'
import Panel from '@/components/Common/Panel.vue'
import Heading from '@/components/Common/Heading.vue'
import Btn from '@/components/Interface/Button.vue'
import Grid from '@/components/Interface/Grid.vue'
import Pagination from '@/components/Interface/Pagination.vue'
import columns from '@/constants/product-import-upload'
import cells from '@/mocks/product-import-upload'
import pagination from '@/mocks/pagination'

export default {

  components: {
    Dashboard,
    Panel,
    Heading,
    Btn,
    Grid,
    Pagination
  },
  data () {
    return {
      columns,
      cells,
      pagination,
      currentPage: 1
    }
  }
}
</script>

<style lang="stylus" scoped>
  .product-import-upload
    &__text
      font-size 14px
      margin-bottom 20px

    &__header
      margin-bottom 20px

    &__content
      padding 20px 30px

    &__heading
      display flex
      margin-bottom 25px

    &__button
      flex-basis 160px
      margin-left 45px

    &__errors
      flex-grow 1
      font-size 14px

    &__grid
      margin-bottom 40px

    &__number
      display flex
      align-items center

    &__status
      width 16px
      height 16px
      margin-left 40px
      line-height 17px
      vertical-align middle

    &__image
      max-width 55px

    &__actions
      display flex
      justify-content center
      align-items center
      margin 0
      margin-left -15px
      padding 0
      list-style none

    &__action
      margin-left 15px

    &__remove
      width 14px
      height 14px

    &__remove-icon
      width 14px
      height 14px

    &__icon
      width 14px
      height 14px
</style>
