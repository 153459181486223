var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-import-upload"},[_c('p',{staticClass:"product-import-upload__text"},[_vm._v(" Здесь вы можете посмотреть статус заданий на выгрузку и удалить товары из задания на выгрузку. ")]),_c('header',{staticClass:"product-import-upload__header"},[_c('dashboard',{attrs:{"type":"import","list":{
        id: '00123',
        title: 'Aли (Акция 2.0)',
        source: 'Список 1.XSLX',
        loaded: 920,
        amount: 1764,
        date: '21.03.2021',
        status: 'working',
        progress: 56
      }}})],1),_c('panel',{staticClass:"product-import-upload__content"},[_c('heading',{attrs:{"level":"2"}},[_vm._v(" Загрузка ")]),_c('div',{staticClass:"product-import-upload__heading"},[_c('div',{staticClass:"product-import-upload__errors"}),_c('div',{staticClass:"product-import-upload__button"},[_c('btn',{attrs:{"block":"","bordered":""}},[_vm._v(" Удалить ")])],1)]),_c('grid',{staticClass:"product-import-upload__grid",attrs:{"auto":"","selectable":"","columns":_vm.columns,"cells":_vm.cells},scopedSlots:_vm._u([{key:"id",fn:function(ref){
      var rowIndex = ref.rowIndex;
return [_c('div',{staticClass:"product-import-upload__number"},[_c('span',[_vm._v(" "+_vm._s(rowIndex + 1)+" ")])])]}},{key:"image",fn:function(ref){
      var image = ref.image;
return [_c('img',{staticClass:"product-import-upload__image",attrs:{"src":image}})]}},{key:"status",fn:function(ref){
      var row = ref.row;
return [_c('ul',{staticClass:"product-import-upload__actions"},[_c('li',{staticClass:"product-import-upload__action"},[(row.status === 'remove')?_c('button',{staticClass:"product-import-upload__remove",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('remove', row)}}},[_c('svg-icon',{staticClass:"product-import-upload__remove-icon",attrs:{"name":"trash"}})],1):_vm._e(),_c('svg-icon',{staticClass:"product-import-upload__icon",attrs:{"name":row.status}})],1)])]}}])}),_c('pagination',{attrs:{"data":_vm.pagination,"current-page":_vm.currentPage},on:{"change":function (page) { return _vm.currentPage = page; }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }