export default [{
  name: '№ строки',
  sortable: true,
  key: 'id',
  type: 'id'
}, {
  name: 'ID',
  sortable: true,
  key: 'id'
}, {
  name: 'Фото',
  sortable: false,
  key: 'image',
  type: 'image'
}, {
  name: 'Артикул',
  sortable: true,
  key: 'vendor_code'
}, {
  name: 'Штрихкод',
  sortable: true,
  key: 'barcode'
}, {
  name: 'Наименование',
  sortable: true,
  key: 'name'
}, {
  name: 'Категория',
  sortable: true,
  key: 'category'
}, {
  name: 'Бренд',
  sortable: true,
  key: 'brand'
}, {
  name: '',
  sortable: false,
  key: 'status',
  type: 'status'
}]
